<template>
  <div>
    <a-card :title="$t('盘点单据')">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />{{ $t("返回") }}
      </a-button>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-row>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="number" :label="$t('盘点单号')">
                <a-input v-model="form.number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="warehouse" :label="$t('盘点仓库')">
                <a-select v-model="form.warehouse" style="width: 100%" @change="onChangeWarehouse">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-row gutter="16">
            <a-col :span="4">
              <client-select v-model="client" :placeholder="$t('货主')" style="width: 100%" />
            </a-col>
            <a-col :span="4">
              <location-search-select
                v-model="location"
                :placeholder="$t('请输入或选择库位')"
                :warehouse="form.warehouse"
                style="width: 100%"
              />
            </a-col>
            <a-space>
              <a-button type="primary" :loading="loading" @click="queryAllMaterial">{{ $t("货品查询") }}</a-button>
              <a-button type="primary" :loading="loading" @click="modalVisible = true">{{ $t("异动查询") }}</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="small"
              :loading="tableLoading"
              :columns="columns"
              :data-source="stockCheckMaterialItems"
              :pagination="false"
            >
              <div slot="quantity" slot-scope="value, item">
                <a-button size="small" @click="openBatchModal(item)">{{ item.quantity }}</a-button>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <a-modal v-model="modalVisible" :title="$t('异动日期范围')" @ok="queryChangeMaterial">
      <a-range-picker
        :defaultValue="[startDate, endDate]"
        :allowClear="false"
        @change="onChangeRangePicker"
        style="width: 100%"
      />
    </a-modal>
  </div>
</template>

<script>
import { warehouseOption, subBatchOption, changeMaterialOption } from "@/api/option";
import { stockCheckOrderCreate } from "@/api/stockCheck";
import { getCheckNumber } from "@/api/data";
import { columns } from "./columns";
import { rules } from "./rules";
import moment from "moment";
import NP from "number-precision";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/index"),
  },
  inject: ["reloadPage"],
  data() {
    return {
      rules,
      moment,
      columns,
      loading: false,
      tableLoading: false,
      form: { number: undefined },
      stockCheckMaterialItems: [],

      warehouseItems: [],
      materialNumber: "",
      materialModalVisible: false,
      client: undefined,
      location: undefined,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(1, "days").format("YYYY-MM-DD"),
      modalVisible: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    defaultWarehouse() {
      return this.$store.state.user.defaultWarehouse;
    },
  },
  methods: {
    initData() {
      this.resetForm();

      getCheckNumber().then((data) => {
        this.form.number = data.number;
      });

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.form = { ...this.form, warehouse: this.defaultWarehouse };
      });
    },
    queryAllMaterial() {
      if (!this.form.warehouse) {
        this.$message.warn(this.$t("请选择仓库"));
        return;
      }

      this.loading = true;
      subBatchOption({
        warehouse: this.form.warehouse,
        location: this.location,
        client: this.client,
        has_stock: true,
        page_size: 999999,
      })
        .then((data) => {
          let stockCheckMaterialItems = [];
          for (let item of data.results) {
            let assignable_quantity =
              item.assigned_quantity < 0 ? item.remain_quantity : NP.minus(item.remain_quantity, item.assigned_quantity);

            stockCheckMaterialItems.push({
              id: item.id,
              sub_batch: item.id,
              location_number: item.location_number,
              batch_number: item.batch_number,
              material_name: item.material_name,
              material_number: item.material_number,
              book_quantity: item.remain_quantity,
              assigned_quantity: item.assigned_quantity,
              assignable_quantity: assignable_quantity,
              client_name: item.client_name,
              status_display: item.status_display,
              stock_in_date: item.stock_in_date,
            });
          }

          this.stockCheckMaterialItems = stockCheckMaterialItems;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    queryChangeMaterial() {
      if (!this.form.warehouse) {
        this.$message.warn(this.$t("请选择仓库"));
        return;
      }

      this.loading = true;
      changeMaterialOption({
        warehouse: this.form.warehouse,
        location: this.location,
        client: this.client,
        has_stock: true,
        start_date: this.startDate,
        end_date: this.endDate,
        page_size: 999999,
      })
        .then((data) => {
          let stockCheckMaterialItems = [];
          for (let item of data.results) {
            let assignable_quantity =
              item.assigned_quantity < 0 ? item.remain_quantity : NP.minus(item.remain_quantity, item.assigned_quantity);

            stockCheckMaterialItems.push({
              id: item.id,
              sub_batch: item.id,
              location_number: item.location_number,
              batch_number: item.batch_number,
              material_name: item.material_name,
              material_number: item.material_number,
              book_quantity: item.remain_quantity,
              assigned_quantity: item.assigned_quantity,
              assignable_quantity: assignable_quantity,
              client_name: item.client_name,
              status_display: item.status_display,
              stock_in_date: item.stock_in_date,
            });
          }

          this.stockCheckMaterialItems = stockCheckMaterialItems;
          this.modalVisible = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangeWarehouse() {
      this.stockCheckMaterialItems = [];
    },
    removeMaterial(index) {
      let stockCheckMaterialItems = [...this.stockCheckMaterialItems];
      stockCheckMaterialItems.splice(index, 1);
      this.stockCheckMaterialItems = [...stockCheckMaterialItems];
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.stockCheckMaterialItems.length == 0) {
            this.$message.warn(this.$t("未添加货品"));
            return;
          }

          let formData = { ...this.form, stock_check_material_items: this.stockCheckMaterialItems };
          this.loading = true;
          stockCheckOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.form = {};
      this.stockCheckMaterialItems = [];
    },
    onChangeRangePicker(date) {
      let startDate = date[0];
      let endDate = date[1];

      this.startDate = startDate.format("YYYY-MM-DD");
      this.endDate = endDate.add(1, "days").format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
